<template>
    <div>
        <b-row>
            <b-col>
                <b-button variant="danger" size="sm" class="mr-1" :disabled="selectedItems.length <= 0" v-b-modal.delete-modal>{{ $t('buttons.delete') }}</b-button>
            </b-col>
            <b-col sm="4" md="5" xl="4" class="my-1">
                <b-form-group
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            :placeholder="$t('generally.tables.tipToSearch')"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">{{ $t('generally.tables.clear') }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    @filtered="onFiltered"
                    stacked="md"
                    :empty-text="$t('generally.tables.emptyText')"
                    :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                    show-empty
                    striped
                    selectable
                    small
                    select-mode="multi"
                    ref="poolTable"
                    class="bg-white"
                    @row-selected="onSelected"
                    sort-by="userName"
                    :busy="tableLoading"
                >
                    <template #table-busy>
                        <div class="text-center my-2">
                            <b-spinner class="align-middle"></b-spinner>
                        </div>
                    </template>

                    <template #head(selected)="{}">
                        <template v-if="selectAll">
                            <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'square']"/></span>
                        </template>
                    </template>

                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true"><font-awesome-icon :icon="['far', 'square']"/></span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>

                    <template #cell(url)="row">
                        <a :href="row.item.url" target="_blank">{{ row.item.url.substring(0, 32) }}<span v-show="row.item.url.length > 32">...</span></a>
                    </template>

                    <template #cell(createdAt)="row">
                        {{ $moment(row.item.createdAt).format('DD.MM.YYYY HH:mm') }}
                    </template>

                    <template #cell(roles)="row">
                        {{ row.item.roles ? row.item.roles.map(item => item.name[locale]).join(', ') : '' }}
                    </template>

                    <template #cell(actions)="row">
                        <b-button variant="primary" :to="{ name: 'AdminUserDetail', params: { id: row.item.id}}" class="btn-xs mb-0 ml-1" :title="$t('buttons.show')">
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                        <b-button variant="orange" class="btn-xs mb-0 ml-1" :title="$t('buttons.edit')" @click="openEdit(row.item)">
                            <font-awesome-icon icon="pencil-alt"/>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                            </ul>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <b-modal
            id="edit-user-modal"
            ref="edit-user-modal"
            :title="$t('pages.admin.user.headers.edit')"
            size="md"
        >
            <b-overlay :show="editModalLoading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                <b-form-group
                    label-cols-sm="12"
                    label-cols-md="3"
                    :label="$t('pages.admin.user.form.role')"
                >
                    <v-select
                        :options="roles"
                        v-model="editedUser.roles"
                        :get-option-label="(option) => option.name[locale]"
                        @keypress.enter.stop
                        multiple
                    ></v-select>
                </b-form-group>
            </b-overlay>
            <template #modal-footer="{ hide }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button size="sm" variant="secondary" @click="hide('forget')">
                    {{ $t('buttons.close') }}
                </b-button>
                <b-button size="sm" variant="orange" @click="onSubmitEdit">
                    {{ $t('buttons.save') }}
                </b-button>
            </template>
        </b-modal>
        <b-modal
            id="delete-modal"
            ref="delete-modal"
            :title="$t('pages.admin.linkManagement.headers.delete')"
            :ok-title="$t('buttons.delete')"
            :cancel-title="$t('buttons.cancel')"
            ok-variant="danger"
            @ok="onDelete"
        >
            {{ $t('pages.admin.linkManagement.messages.delete.' + (selectedItems.length > 1 ? 'multi' : 'single'), {count: selectedItems.length}) }}
        </b-modal>
    </div>
</template>

<script>
import userService from "../../../services/userService";
import {EventBus} from "../../../services/EventBus";
import roleService from "../../../services/roleService";
import mainServices from "../../../services/mainServices";

export default {
    name: "UserList",
    data: () => ({
        fields: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [5, 10, 15, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        selectAll: false,
        selectedItems: [],
        items: [],
        eventBus: null,
        roles: [],
        editedUser: {
            id: '',
            roles: []
        },
        locale: 'de',
        editModalLoading: false,
        tableLoading: true
    }),
    watch: {
        '$i18n.locale': function (newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.fields = [
            {key: 'selected', label: '', sortable: false},
            {key: 'userName', label: this.$t('pages.admin.user.table.username'), sortable: true, sortDirection: 'asc'},
            {key: 'firstName', label: this.$t('pages.admin.user.table.firstName'), sortable: true, sortDirection: 'asc'},
            {key: 'lastName', label: this.$t('pages.admin.user.table.lastName'), sortable: true, sortDirection: 'asc'},
            {key: 'email', label: this.$t('pages.admin.user.table.email'), sortable: true},
            {key: 'roles', label: this.$t('pages.admin.user.table.role'), sortable: true},
            {key: 'actions', label: this.$t('generally.tables.actions')}
        ]
        this.loadRoles();
        this.load();
        this.locale = mainServices.getLocale();
        // adding EventBus listener
        this.eventBus = EventBus.$on('sync', () => {
            this.loadRoles();
            this.load();
        });

    },
    beforeDestroy() {
        // removing EventBus listener
        EventBus.$off('sync', this.eventBus);
    },
    methods: {
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        load() {
            userService.list().then(response => {
                this.items = response.data;
                this.tableLoading = false;
            })
            .catch(() => {
                this.tableLoading = false;
            });
        },
        loadRoles(){
            roleService.getAll().then(response => {
                this.roles = response.data;
            }).catch();
        },
        checkAll(){
            this.selectAll = !this.selectAll;
            if(this.selectAll){
                this.$refs.poolTable.selectAllRows()
                this.selectedItems = this.items;
            } else {
                this.$refs.poolTable.clearSelected()
                this.selectedItems = [];
            }
        },
        onSelected(items){
            this.selectAll = this.items.length === items.length;
            this.selectedItems = items;
        },
        openEdit(item){
            this.editedUser.id = item.id;
            this.editedUser.roles = item.roles;
            this.$bvModal.show('edit-user-modal');
        },
        /**
         * Triggers a request to change user roles to the backend
         */
        onSubmitEdit(){
            if(this.editedUser.id !== '' && !this.editModalLoading) {
                this.editModalLoading = true;
                let data = {
                    roleIds: this.editedUser.roles.map(item => item.id)
                }
                userService.editUser(this.editedUser.id, data).then(() => {
                    this.$toastr.s(this.$t('pages.admin.user.messages.success.edited'));
                    this.load();
                    this.$bvModal.hide('edit-user-modal');
                    this.editModalLoading = false;
                    this.editedUser.id = '';
                    this.editedUser.roles = [];
                }).catch(error => {
                    this.errorHandler(error)
                    this.editModalLoading = false;
                });
            }
        },
        /**
         * Triggers a request to delete user to the backend
         */
        onDelete(){
            userService.removeUser({ ids: this.selectedItems.map(item => (item.id)) }).then(() => {
                this.$toastr.s(this.$t('pages.admin.user.messages.success.deleted'));
                this.load();
            }).catch(error => {
                this.errorHandler(error)
            });
        }
    }
};
</script>

<style scoped>

</style>
