import api from "./api";

export default {
    /**
     * Get all Roles
     * @returns {Promise}
     */
    getAll(){
        return new Promise((resolve, reject) => {
            api().get("roles").then(response => {
                resolve(response);
            }).catch(response => {
                reject(response);
            });
        });
    }
}
